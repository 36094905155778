import React, { useEffect, useState, useCallback } from "react";
import { Tooltip, Card, Layout, Alert } from "antd";
import "antd/dist/reset.css";
import { Content, Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import logo from "./images/logo-fo2.webp";
import "./App.css";

const getColor = (value) => {
  if (value > 20) return "rgba(255, 0, 0, 0.7)";
  if (value > 10) return "rgba(255, 165, 0, 0.7)";
  if (value > 0) return "rgba(255, 255, 0, 0.7)";
  return "rgba(0, 128, 0, 0.7)";
};

const getMessage = (value) => {
  if (value > 20) return "The website was largely unavailable.";
  if (value > 10) return "The website encountered major disruptions.";
  if (value > 0) return "The website had some minor problems.";
  return "The website was fully functional.";
};

const BarChart = React.memo(({ clientData, title, isLive }) => {
  const barWidth = 968 / clientData.length;

  return (
    <Card.Grid size="small" className="bar-chart-grid">
      <Title level={5} className="bar-chart-title">
        {title}{" "}
        <span
          className={`bar-status ${isLive ? "operational" : "not-reachable"}`}
        >
          {isLive ? "Operational" : "Not Reachable"}
        </span>
      </Title>
      <div className="bar-chart-container">
        <div className="bar-chart-bars">
          {clientData.map((item, index) => (
            <Tooltip
              key={index}
              color={"#f0f0f0"}
              title={
                <div className="bar-chart-tooltip">
                  <p style={{ fontWeight: "bold" }}>{item.date}</p>
                  <p>
                    {item.not_live_count !== 0 ? (
                      <>
                        <span
                          style={{
                            backgroundColor: `${getColor(item.not_live_count)}`,
                            display: "inline-block",
                            padding: "2px 6px",
                          }}
                        >
                          Fail Attempt:
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {item.not_live_count}
                          </span>
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {getMessage(item.not_live_count)}
                  </p>
                </div>
              }
              placement="top"
            >
              <div
                className="bar-chart-bar"
                style={{
                  width: `${barWidth}px`,
                  backgroundColor: getColor(item.not_live_count),
                }}
              ></div>
            </Tooltip>
          ))}
        </div>
        <div className="bar-chart-legend">
          <div className="bar-chart-legend-item">90 days ago</div>
          <div className="bar-chart-legend-item">Today</div>
        </div>
      </div>
    </Card.Grid>
  );
});

const App = () => {
  const [webApps, setWebApps] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [error, setError] = useState(null);

  const fetchWebApps = useCallback(async () => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/web-apps`, {
        signal,
      });
      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();
      setWebApps((webApps) =>
        result.data.map(({ id, name, isLive }) => {
          const curWebApp = webApps.find((webApp) => webApp.id === id);

          return {
            id,
            name,
            isLive,
            report: curWebApp ? curWebApp.report : [],
          };
        })
      );
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching fetchWebApps:", error);
        setError(error);
      }
    }

    return () => controller.abort();
  }, []);

  const fetchWebAppStatusReport = useCallback(async () => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/status`, {
        signal,
      });
      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();
      setWebApps((webApps) =>
        webApps.map((webApp) => {
          const report = result.data[webApp.id] || [];
          return { ...webApp, report };
        })
      );
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching fetchWebAppStatusReport:", error);
        setError(error);
      }
    }

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchWebApps();
        await fetchWebAppStatusReport();
        setLastUpdated(new Date().toLocaleTimeString());
      } catch (e) {
        setError(e);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000);

    return () => clearInterval(interval);
  }, [fetchWebApps, fetchWebAppStatusReport]);

  const downWebApps = webApps.filter((webApp) => !webApp.isLive);
  const allOperational = downWebApps.length === 0;
  const alertMessage = allOperational
    ? "All Websites are Operational"
    : `The following websites are not reachable: ${downWebApps
        .map((webApp) => webApp.name)
        .join(", ")}`;
  const alertType = allOperational ? "success" : "error";

  if (error) {
    return (
      <Alert
        message="An error occurred"
        description="We encountered an issue while loading the data. Please try again later."
        type="error"
        showIcon
      />
    );
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ backgroundColor: "#fff", padding: 0 }}>
        <div className="header-content">
          <div className="header-content-container">
            <img src={logo} alt="Top Banner" className="header-logo" />
          </div>
        </div>
      </Header>
      <Content className="content">
        <div className="content-container">
          {webApps.length > 0 && (
            <>
              <Alert
                className="page-status"
                message={alertMessage}
                type={alertType}
                showIcon
              />

              <Card>
                <div className="card-hint">
                  <div className="card-hint-item">
                    Uptime over the past 90 days
                  </div>
                  <div className="card-hint-item">
                    Last updated: {lastUpdated ? lastUpdated : "Fetching..."}
                  </div>
                </div>

                {webApps.map(({ id, name, isLive, report }) => (
                  <BarChart
                    clientData={report}
                    title={name}
                    key={id}
                    isLive={isLive}
                  />
                ))}
              </Card>
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default App;
