import React, { Component } from "react";
import { Alert } from "antd";

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Alert
            message="Something went wrong!"
            description="We encountered an issue while processing your request. Please try again later."
            type="error"
            showIcon
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default Error;
